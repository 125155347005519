'use strict';

import {Action} from './Action';

class SlowNetworkSingleton extends Action {
    constructor() { super(); }

    getTag() {
        return 'slow_network';
    }

    _execute() {
        return Promise.resolve();
    }
}

const SlowNetwork = new SlowNetworkSingleton();

export default SlowNetwork;
export { SlowNetwork };
