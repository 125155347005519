'use strict';

import {Store} from '@totalpave/store';

let _instance = null;

export class NetworkStore extends Store {
    constructor() {
        super();
        this._slowNetwork = false;
    }

    static getInstance() {
        if (!_instance) {
            _instance = new NetworkStore();
        }
        return _instance;
    }

    _reset() {
        this._slowNetwork = false;
        return Promise.resolve();
    }

    _update(actionData) {
        switch (actionData.getTag()) {
            case 'slow_network':
                this._slowNetwork = true;
                return true;
            case 'logout_action':
                // this._reset();
                return true;
        }

        return false;
    }

    isSlowNetwork() {
        return this._slowNetwork;
    }
}
