'use strict';

import { Action } from './Action';

/*
Used to propagate the sentry client to stores.
 */
class InitSentrySingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'init-sentry';
    }

    async _execute(sentry) {
        return sentry;
    }
}

const InitSentry = new InitSentrySingleton();

export { InitSentry };
