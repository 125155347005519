'use strict';

import {HTTPConstants} from './HTTPConstants';
import {ApplicationInstance} from '@totalpave/application-instance';
import {UserStore} from '../store/UserStore';
import {SlowNetwork} from '../actions/SlowNetwork';
import {RequestLogoutAction} from '../actions/RequestLogoutAction';
import {AccessTokenUpdate} from '../actions/AccessTokenUpdate';
import {TPError, StormError} from '@totalpave/error';
import {MessageType} from '@totalpave/finterfaces';
import {Localization} from '@totalpave/localization';

const NO_DATA = `|${0x0}|`;
const DEFAULT_TIMEOUT = 60000; //in milliseconds
const MAINTENANCE_MESSAGE = 'TotalPave is currently offline for maintenance, please try again later.';
const NO_CONNECTION_ERROR = {
    code    : 0,
    message : 'No network connectivity'
};

let renewPromise = null; //Shared variable

class NetworkClient {
    constructor() {
        this.config = ApplicationInstance.getInstance().getConfig();
        this._base = null;
        this._token = UserStore.getInstance().getAccessToken();
        this._setBase(this.config.base);
        UserStore.getInstance().register(() => {
            this._onUserUpdated();
        });
    }

    $getTimezoneOffset() {
        return new Date().getTimezoneOffset();
    }

    _onUserUpdated() {
        this._token = UserStore.getInstance().getAccessToken();
    }

    urlSuffix() {
        return '/';
    }

    getToken() {
        return this._token;
    }

    _createURL(url, queryParams) {
        let queryString = '';

        for (let i in queryParams) {
            if (queryString === '') {
                queryString = '?' + i + '=' + queryParams[i];
            }
            else {
                queryString += '&' + i + '=' + queryParams[i];
            }
        }

        return url + this.urlSuffix() + queryString;
    }

    // If false, getVersion will not be used.
    _useClientVersion() {
        return true;
    }

    /**
    * @deprecated
    * getVersion is being deprecated in favor of manually managing your API versions.
    * 
    * Override `_useClientVersion` and make it return false.
    * 
    * Update your APIs to include the API version in the URL.
    * Example: this.get('myservice/myapi') should be updated to this.get('v1/myservice/myapi')
    */
    getVersion() {
        if (!this._useClientVersion()) {
            console.warn('getVersion was called despite _useClientVersion being false.');
        }
        return 'v1';
    }

    _getServiceBase() {
        throw new Error('Abstract method _getServiceBase() invoked.');
    }

    _setBase(base) {
        this._base = base + 'api/' + this._getServiceBase() + '/';
        if (this._useClientVersion()) {
            this._base += this.getVersion() + '/';
        }
    }

    getBase() {
        return this._base;
    }

    getServiceURL(url, queryParams) {
        let serviceUrl = this.getBase();

        if (url) {
            serviceUrl += url;
        }

        serviceUrl += this.urlSuffix();

        if (queryParams) {
            let queryString;
            for (let i in queryParams) {
                if (queryString === '') {
                    queryString = '?' + i + '=' + queryParams[i];
                }
                else {
                    queryString += '&' + i + '=' + queryParams[i];
                }
            }
            serviceUrl += queryString;
        }

        return serviceUrl;
    }

    request(method, url, data, headers, additionalOpts) {
        if (!additionalOpts) {
            additionalOpts = {};
        }

        let defaultHeaders = {
            "X-TP-SOURCE-TARGET": this.config.appType,
            "X-TP-SOURCE-VERSION": ApplicationInstance.getInstance().getVersion(),
            "X-TP-TIMEZONE": this.$getTimezoneOffset()
        };

        if (!(data instanceof FormData)) {
            defaultHeaders["Content-Type"] = 'application/json';
        }

        let config = {
            method : method,
            url : this.getBase() + url,
            data : data === NO_DATA ? undefined : data,
            headers : defaultHeaders,
            blob : !!additionalOpts.blob,
            timeout: additionalOpts.timeout || DEFAULT_TIMEOUT
        };

        for (let header in headers) {
            config.headers[header] = headers[header];
        }

        if (this._token) {
            config.headers['X-BT-AUTH'] = this._token;
        }

        return this._request(config);
    }

    _shouldLogout(data) {
        if (data.code === 2) {
            return true;
        }

        if (data.name === 'EntityNotFoundError') {
            return true;
        }

        return false;
    }

    _request(config) {
        let localization = Localization.getInstance();
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(config.method, config.url, true);

            //Note this cannot exceed 60 seconds on Chrome
            xhr.timeout = config.timeout;

            for (let i in config.headers) {
                xhr.setRequestHeader(i, config.headers[i]);
            }

            if (config.blob) {
                xhr.responseType = 'blob';
            }

            xhr.onload = () => {
                clearTimeout(timer);
                clearTimeout(slowTimer);
                let data;

                let handleError = (data) => {
                    if (data.code === 'TokenExpiredError') {
                        this._renewToken().then(() => {
                            config.headers['X-BT-AUTH'] = UserStore.getInstance().getAccessToken();
                            this._request(config).then(resolve).catch(reject);
                        }).catch((error) => {
                            let e;
                            if (error && error.locale && error.locale.code && localization.hasKey(error.locale.code)) {
                                e = new TPError(localization.resolve(error.locale.code, error.locale.parameters));
                            }
                            else {
                                e = new TPError(error);
                            }
                            e.dispatch(MessageType.ERROR_DIALOG);
                            if (this._shouldLogout(error)) {
                                RequestLogoutAction.execute();
                            }
                            reject(e);
                        });
                    }
                    else if (data.code === 'JsonWebTokenError') {
                        data.message = data.reason;
                        let e = new StormError(xhr, data);
                        e.dispatch();
                        RequestLogoutAction.execute();
                        reject(e);
                    }
                    else {
                        if (xhr.status === HTTPConstants.SERVICE_UNAVAILABLE) {
                            // Backwards compatibility for pre-locale supported applications
                            if (localization.hasKey('tp-app-common/NetworkClient/maintenance')) {
                                data = localization.resolve('tp-app-common/NetworkClient/maintenance');
                            }
                            else {
                                data = MAINTENANCE_MESSAGE;
                            }
                        }
                        else if (/html/.test(xhr.getResponseHeader('content-type'))) {
                            // This error must be a nginx error, not a storm error
                            if (localization.hasKey('tp-app-common/NetworkClient/nginx-error')) {
                                data = localization.resolve('tp-app-common/NetworkClient/nginx-error', {
                                    code: xhr.status
                                });
                            }
                            else {
                                data = `Network Error Code: ${xhr.status}`;
                            }
                        }
                        reject(new StormError(xhr, data));
                    }
                };

                if (xhr.response instanceof Blob) {
                    data = xhr.response;
                }
                else {
                    try {
                        data = JSON.parse(xhr.responseText);
                    }
                    catch (ex) {
                        data = xhr.responseText;
                    }
                }

                if (xhr.status >= HTTPConstants.OK && xhr.status < HTTPConstants.BAD_REQUEST) {
                    resolve(data);
                }
                else {
                    if (data instanceof Blob) {
                        this._readBlob(data).then((data) => {
                            handleError(data);
                        }).catch(reject);
                    }
                    else {
                        handleError(data);
                    }
                }
            };

            xhr.onerror = () => {
                let data = null;
                try {
                    data = JSON.parse(xhr.responseText);
                } // eslint-disable-next-line
                catch(ex) {}

                if (!data) {
                    let e;
                    if (localization.hasKey('tp-app-common/NetworkClient/no-network')) {
                        e = localization.resolve('tp-app-common/NetworkClient/no-network');
                    }
                    else {
                        e = NO_CONNECTION_ERROR;
                    }
                    return reject(e);
                }

                let handleError = () => {
                    clearTimeout(timer);
                    clearTimeout(slowTimer);

                    if (xhr.status === HTTPConstants.BAD_GATEWAY || (xhr.status === HTTPConstants.NOT_FOUND && !data.error)) {
                        let errorMessage;
                        if (localization.hasKey('tp-app-common/NetworkClient/internal-error')) {
                            errorMessage = localization.resolve('tp-app-common/NetworkClient/internal-error');
                        }
                        else {
                            errorMessage = 'Internal error';
                        }
                        return reject({
                            error : errorMessage
                        });
                    }

                    if (data.code === 'TokenExpiredError') {
                        this._renewToken().then(() => {
                            config.headers['X-BT-AUTH'] = UserStore.getInstance().getAccessToken();
                            this._request(config).then(resolve).catch(reject);
                        }).catch((data) => {
                            if (data instanceof Blob) {
                                this._readBlob(data).then((error) => {
                                    let e = new TPError(error);
                                    e.dispatch(MessageType.ERROR_DIALOG);
                                    if (this._shouldLogout(error)) {
                                        RequestLogoutAction.execute();
                                    }
                                    reject(e);
                                });
                            }
                            else {
                                let e = new TPError(data);
                                e.dispatch(MessageType.ERROR_DIALOG);
                                if (this._shouldLogout(data)) {
                                    RequestLogoutAction.execute();
                                }
                                reject(e);
                            }
                        });
                    }
                    else {
                        reject(new StormError(xhr, data));
                    }
                };

                if (data instanceof Blob) {
                    this._readBlob(data).then((result) => {
                        data = result;
                        handleError();
                    }).catch(reject);
                }
                else {
                    handleError();
                }
            };

            if (config.headers['Content-Type'] === 'application/json') {
                if (config.data && typeof config.data !== 'string') {
                    config.data = JSON.stringify(config.data);
                }
            }

            if (config.data !== undefined) {
                xhr.send(config.data);
            }
            else {
                //If we pass in undefined in send() IE will actually send "undefined" to the server which causes issues on some APIs
                xhr.send();
            }

            let slowTimer = setTimeout(() => {
                SlowNetwork.execute();
            }, 3000);

            let timer = setTimeout(() => {
                xhr.abort();
                reject('timeout');
            }, 60 * 1000);
        });
    }

    _readBlob(blob) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onerror = (e) => {
                let error = new TPError(e);
                error.dispatch();
                reject(error);
            };

            reader.onloadend = (e) => {
                let text = e.srcElement.result;
                let data;
                try {
                    data = JSON.parse(text);
                }
                catch (ex) {
                    data = text;
                }
                resolve(data);
            };

            reader.readAsText(blob);
        });
    }

    _renewToken() {
        let localization = Localization.getInstance();
        if (renewPromise) {
            return renewPromise;
        }

        let config = {
            url : this.config.base + 'api/auth/v1/renew/',
            method: 'POST',
            headers : {
                'X-BT-AUTH': this._token,
                "X-TP-SOURCE-TARGET": this.config.appType,
                "X-TP-SOURCE-VERSION": ApplicationInstance.getInstance().getVersion(),
                "X-TP-TIMEZONE": this.$getTimezoneOffset()
            },
            data: {
                renewToken: UserStore.getInstance().getRenewToken()
            }
        };

        renewPromise = new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(config.method, config.url);

            for (let i in config.headers) {
                xhr.setRequestHeader(i, config.headers[i]);
            }

            xhr.onload = () => {
                clearTimeout(timer);
                clearTimeout(slowTimer);
                let data;
                try {
                    data = JSON.parse(xhr.responseText);
                }
                catch (ex) {
                    data = xhr.responseText;
                }

                if (xhr.status >= HTTPConstants.OK && xhr.status < HTTPConstants.BAD_REQUEST) {
                    AccessTokenUpdate.execute({user: data}).then(() => {
                        resolve(data);
                        renewPromise = null;
                    }).catch(reject);
                }
                else {
                    if (xhr.status === HTTPConstants.SERVICE_UNAVAILABLE) {
                        // Backwards compatibility for pre-locale supported applications
                        if (localization.hasKey('tp-app-common/NetworkClient/maintenance')) {
                            data = localization.resolve('tp-app-common/NetworkClient/maintenance');
                        }
                        else {
                            data = MAINTENANCE_MESSAGE;
                        }
                    }
                    else if (/html/.test(xhr.getResponseHeader('content-type'))) {
                        // This error must be a nginx error, not a storm error
                        if (localization.hasKey('tp-app-common/NetworkClient/nginx-error')) {
                            data = localization.resolve('tp-app-common/NetworkClient/nginx-error', {
                                code: xhr.status
                            });
                        }
                        else {
                            data = `Network Error Code: ${xhr.status}`;
                        }
                    }
                    reject(data);
                    renewPromise = null;
                }
            };

            xhr.onerror = () => {
                clearTimeout(timer);
                clearTimeout(slowTimer);
                let data = null;
                try {
                    data = JSON.parse(xhr.responseText);
                }
                catch (ex) {
                    data = xhr.responseText;
                }

                if (!data) {
                    let e;
                    if (localization.hasKey('tp-app-common/NetworkClient/no-network')) {
                        e = localization.resolve('tp-app-common/NetworkClient/no-network');
                    }
                    else {
                        e = NO_CONNECTION_ERROR;
                    }
                    reject(e);
                    renewPromise = null;
                    return;
                }

                if (xhr.status === HTTPConstants.BAD_GATEWAY || (xhr.status === HTTPConstants.NOT_FOUND && !data.error)) {
                    let errorMessage;
                    if (localization.hasKey('tp-app-common/NetworkClient/internal-error')) {
                        errorMessage = localization.resolve('tp-app-common/NetworkClient/internal-error');
                    }
                    else {
                        errorMessage = 'Internal error';
                    }
                    return reject({
                        error : errorMessage
                    });
                }

                reject(data);
                renewPromise = null;
            };

            if (config.data && typeof config.data !== 'string') {
                config.data = JSON.stringify(config.data);
            }

            xhr.send(config.data);

            let slowTimer = setTimeout(() => {
                SlowNetwork.execute();
            }, 3000);

            let timer = setTimeout(() => {
                xhr.abort();
                reject('timeout');
                renewPromise = null;
            }, 60 * 1000);
        });

        return renewPromise;
    }

    get(url, data, headers, additionalOpts) {
        return this.request(HTTPConstants.GET, this._createURL(url, data), NO_DATA, headers, additionalOpts);
    }

    post(url, data, headers, additionalOpts) {
        return this.request(HTTPConstants.POST, this._createURL(url), data, headers, additionalOpts);
    }

    head(url, data, headers, additionalOpts) {
        return this.request(HTTPConstants.HEAD, this._createURL(url), data, headers, additionalOpts);
    }

    put(url, data, headers, additionalOpts) {
        return this.request(HTTPConstants.PUT, this._createURL(url), data, headers, additionalOpts);
    }

    del(url, data, headers, additionalOpts) {
        return this.request(HTTPConstants.DELETE, this._createURL(url), data, headers, additionalOpts);
    }
}

export { NetworkClient };
