'use strict';

import {Action} from './Action';

class ClearMessageSingleton extends Action {
    constructor() {
        super();
        this.setForceExecute(true); //Allow messages to be removed while logging out.
    }

    getTag() {
        return 'clear_message';
    }

    _execute({message} = {}) {
        return Promise.resolve(message);
    }
}

const ClearMessageAction = new ClearMessageSingleton();

export { ClearMessageAction };
