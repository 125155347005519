'use strict';

import * as React from 'react';
import { ResetPasswordModal } from './ResetPasswordModal';
import {InputMode, Textfield} from './Textfield';
import { Button } from './Button';
import { Label } from './Label';
import { IconFactory } from '../factories/IconFactory';
import { NotificationFactory } from '../factories/NotificationFactory';
import { ModalPushAction } from '../actions/ModalPushAction';
import { LoginAction } from '../actions/LoginAction';
import { LoadOrganizationPreferences } from '../actions/LoadOrganizationPreferences';
import { LoadCurrencyLocalization } from '../actions/LoadCurrencyLocalization';
import { TPError } from '@totalpave/error';
import {ApplicationInstance} from '@totalpave/application-instance';
import { UserStore } from '../store/UserStore';
import {
    getRouter
} from '@breautek/router';
import PropTypes from 'prop-types';
import '../style/LoginForm.less';
import {Localization} from '@totalpave/localization';
import { ClassName } from '../utils/ClassName';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this._email = "";
        this._password = "";

        this.state = {
            loginButtonEnabled : true,
            loginText : 'tp-app-common/LoginForm/login-button-label'
        };

        this._login = this._login.bind(this);
        this._onEmailChange = this._onEmailChange.bind(this);
        this._onPasswordChange = this._onPasswordChange.bind(this);
        this._forgotPasswordTap = this._forgotPasswordTap.bind(this);

        this.$hasFiredLogoDeprecation = false;
    }

    shouldShowLogo() {
        return this.props.showLogo === undefined || this.props.showLogo;
    }

    getClassName() {
        return 'LoginForm';
    }

    _forgotPasswordTap() {
        ModalPushAction.execute({modal: ResetPasswordModal}); 
    }

    _onEmailChange(value) {
        this._email = value;
    }

    _onPasswordChange(value) {
        this._password = value;
    }

    _login() {
        this.setState({
            loginButtonEnabled : false,
            loginText : 'tp-app-common/LoginForm/login-button-wait-label'
        });
        
        LoginAction.execute({email: this._email, password: this._password, persist: true}).then(() => {
            if (UserStore.getInstance().isRenewValid()) {
                LoadOrganizationPreferences.execute();
                new LoadCurrencyLocalization().execute(UserStore.getInstance().getOrganizationCurrency()).catch(console.log);
                (this.props.onLoginHandler ? this.props.onLoginHandler() : Promise.resolve()).then(() => {
                    getRouter().replaceState(ApplicationInstance.getInstance().getHomeURL());
                }).catch((error) => {
                    new TPError({
                        stack: new Error().stack,
                        message: "LoginForm onLoginHandler prop errored out. We're going to try to route to home page; but, stuff may go wrong.",
                        details: error
                    });
                    getRouter().replaceState(ApplicationInstance.getInstance().getHomeURL());
                });
            }
        }).catch((error) => {
            this.setState({
                loginButtonEnabled : true,
                loginText : 'tp-app-common/LoginForm/login-button-label'
            });
            ApplicationInstance.getInstance().getNotificationFactory().create(
                NotificationFactory.ALERT,
                {
                    message: error.getMessage()
                }
            );
        });
    }

    $fireLogoDeprecation() {
        if (!this.$hasFiredLogoDeprecation) {
            this.$hasFiredLogoDeprecation = true;
            ApplicationInstance.getInstance().getLogger().deprecated('Render the TP Logo yourself');
        }
    }

    render() {
        // value={this._email}
        // value={this._password}
        let localization = Localization.getInstance();

        let logo = null;
        if (this.shouldShowLogo()) {
            this.$fireLogoDeprecation();
            logo = IconFactory.create(IconFactory.Icons.LOGO_ICON, {width:300});
        }

        let preIcon = null;
        if (!this.state.loginButtonEnabled) {
            preIcon = IconFactory.create(IconFactory.Icons.LOADING_SPINNER, {size:'lg', isAnimated: true});
        }

        return (
            <div className={
                ClassName.execute({
                    [this.getClassName()]: true,
                    'is-showing-logo': this.shouldShowLogo()
                })
            }>
                {logo}
                <div className="content-wrapper">{/*this div separates form + children prop from logo*/}
                    <div className="form">
                        <Textfield 
                            type={InputMode.EMAIL} 
                            placeholder={localization.resolve('tp-app-common/LoginForm/email-input-placeholder')}
                            onChange={this._onEmailChange}
                        />
                        <Textfield 
                            type={InputMode.PASSWORD} 
                            placeholder={localization.resolve('tp-app-common/LoginForm/password-input-placeholder')}
                            onChange={this._onPasswordChange}
                        />
                        <Button 
                            text={localization.resolve(this.state.loginText)}
                            preIcon={preIcon} 
                            color="green" 
                            onClick={this._login} 
                            waitForCallback={!this.state.loginButtonEnabled} 
                        />
                        <Label text={localization.resolve('tp-app-common/LoginForm/forgot-password-link-label')} onTap={this._forgotPasswordTap} />
                        {this.props.showNeedAnAccount && (<span className="needAnAccount">{localization.resolve('tp-app-common/LoginForm/help-label')} <a href="https://totalpave.com/contact">{localization.resolve('tp-app-common/LoginForm/help-link-label')}</a></span>)}
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

LoginForm.propTypes = {
    showNeedAnAccount: PropTypes.bool,
    onLoginHandler: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    showLogo: PropTypes.bool
};

export { LoginForm };
