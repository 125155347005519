'use strict';

import {Store} from '@totalpave/store';
import {IDGenerator} from '@totalpave/idgenerator';
import {ObjectUtils} from '@totalpave/object';

let _instance = null;

/*
A datastore that maintains a stack of opened modals.
 */
export class ModalStore extends Store {
    constructor() {
        super();
        this._modalStack = [];
        this._modalMap = new Map();
        this._zIndex = 10000;
    }

    static getInstance() {
        if (!_instance) {
            _instance = new ModalStore();
        }
        return _instance;
    }

    _reset() {
        this._zIndex = 10000;
        let newModalMap = new Map();
        this._modalStack = this._modalStack.filter((modal) => {
            if (ObjectUtils.isVoid(modal.props.persistent) ? false : modal.props.persistent) {
                newModalMap.set(modal.props.id, modal);
                return true;
            }
            else {
                return false;
            }
        });
        this._modalMap = newModalMap;
        return Promise.resolve();
    }

    getActiveModal() {
        if (this._modalStack.length > 0) {
            return this._modalStack[this._modalStack.length - 1];
        }
        else {
            return null;
        }
    }

    isModalVisible() {
        return this._modalStack.length > 0;
    }

    containsModal(id) {
        return this._modalMap.has(id);
    }

    getModals() {
        return this._modalStack;
    }

    _update(actionData) {
        let data;
        let modal;
        switch (actionData.getTag()) {
            case 'modal_push':
                data = actionData.getData();
                data.props.z = this._zIndex++;
                if (ObjectUtils.isVoid(data.props.id)) {
                    data.props.id = IDGenerator.generate();
                }
                if (ObjectUtils.isVoid(data.props.key)) {
                    data.props.key = data.props.id;
                }
                this._modalStack.push(data);
                this._modalMap.set(data.props.id, data);
                return true;
            case 'modal_pop':
                modal = this._modalStack.pop();
                this._modalMap.delete(modal.props.id);
                if (modal && modal.props && modal.props.onClose) {
                    modal.props.onClose();
                }
                return true;
            case 'logout_action':
                // this._reset();
                return true;
        }

        return false;
    }
}
