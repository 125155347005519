'use strict';

import {Action} from './Action';

class ModalPopActionSingleton extends Action {
    constructor() {
        super();
        this.setForceExecute(true); //Allow modals to be removed while logging out.
    }

    getTag() {
        return 'modal_pop';
    }

    /**
     * public execute
     *
     *  Closes the top-most modal of the modal stack, and show the next top-most modal if any.
     * 
     * @return {void}
     */
    _execute() {
        return Promise.resolve();
    }
}

const ModalPopAction = new ModalPopActionSingleton();

export { ModalPopAction };
