'use strict';

import {Action} from './Action';

class ViewportSizeUpdateSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'viewport_size_update';
    }

    /**
     * public execute
     * @return {void}
     */
    _execute() {
        return Promise.resolve();
    }
}

const ViewportSizeUpdate = new ViewportSizeUpdateSingleton();

export { ViewportSizeUpdate };
