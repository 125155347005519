'use strict';

import * as React from 'react';
import {ApplicationInstance} from '@totalpave/application-instance';
import {Factory} from './Factory';
import {SVGIcon} from '../components/SVGIcon';
import {FontAwesomeIcon} from '../components/FontAwesomeIcon';
import { SVGUtils } from '../utils/SVGUtils';
import { Color } from '../utils/Color';

//If adding an icon from res, don't forge to set props.className.
import PlusCircleThin from '../assets/deferred/plus_circle_thin.svg';
import CancelCircleThin from '../assets/deferred/cancel_circle_thin.svg';
import LongPressHand from '../assets/deferred/long_press_hand.svg';
import ActiveLocation from '../assets/deferred/location_icon_active.svg';
import InactiveLocation from '../assets/deferred/location_icon_unactive.svg';
import TPLogoWhite from '../assets/deferred/logowhite.svg';
import CircularLogo from '../assets/deferred/CircularLogo.svg'
import Arrow from '../assets/inline/arrow.svg';
import LogsList from '../assets/deferred/logslist.svg';

const Icons = {
    LOCATION: "custom_location",
    CAMERA: "camera",
    CIRCLE_CAMERA: "circle_camera",
    CIRCLE: "circle",
    EMPTY_CIRCLE: 'circle-o',
    CHECK_SQUARE: "check-square",
    PLUS_SQUARE: 'plus-square',
    PLUS_CIRCLE: 'plus-circle',
    MAP: 'map',
    MAP_MARKER: "map-marker",
    HOME: 'home',
    BACK: "back",
    DRAGGABLE: 'draggable',
    ARROW_RIGHT: 'arrow-right',
    WRENCH: 'wrench',
    REFRESH: 'refresh',
    EDIT: 'edit',
    HELP: 'help',
    LINK: 'link',
    IRI_NOTE: 'iri-note',
    SAVE: 'save',
    THIN_CANCEL: 'thin-cancel',
    CANCEL: "cancel",
    CANCEL_CIRCLE: 'cancel-circle',
    DELETE: "delete",
    DELETE_THICK: 'delete-thick',
    DELETE_THIN: "delete-thin",
    MAP_THEME: 'map-theme',
    CHEVRON_UP: 'chevron-up',
    CHEVRON_RIGHT: "chevron-right",
    CHEVRON_DOWN: "chevron-down",
    CHEVRON_LEFT: 'chevron-left',
    SEARCH: 'search',
    LINE_CHART: 'line-chart',
    BAR_CHART: 'bar-chart',
    COG: 'cog',
    MENU: "bars", //TP Tracker orginally used MENU to represent bars.
    BARS: 'bars',
    POWER_OFF: 'power-off',
    TABLE: 'table',
    PLUS: 'plus',
    MINUS: 'minus',
    MINUS_SQUARE: 'minus-square',
    CHECK: "check",
    CARET_UP: 'caret-up',
    CARET_RIGHT: 'caret-right',
    CARET_DOWN: 'caret-down',
    CARET_LEFT: 'caret-left',
    SIGN_OUT: 'sign-out',
    LOGO_ICON: "logo-icon",
    CIRCULAR_LOGO_ICON: "circular-logo-icon",
    CHECK_CICLE_FILLED: "check-circle-filled",
    CLOUD_UPLOAD: "cloud-upload",
    UPLOAD: "upload",
    CLOCK: "clock",
    LOADING_SPINNER: "loading-spinner",
    LOGS_LIST: "logs-list",
    LIST: "list",
    PLUS_CIRCLE_THIN: 'plus-circle-thin',
    CANCEL_CIRCLE_THIN: 'cancel-circle-thin',
    LONG_PRESS_HAND: 'long-press-hand',
    INFO: 'info',
    CROSSHAIRS: 'crosshairs',
    WARNING: 'exclamation-triangle',
    CALENDAR: 'calendar',
    RESIZE_HANDLE: 'resize-handle',
    UNDO: 'undo',
    PENCIL_PAGE: 'pencil-page',
    LANGUAGE: 'language'
}

class IconFactorySingleton extends Factory {
    constructor() { super(); }

    create(icon, props) {
        if (!props) {
            props = {};
        }

        let width = null, height = null;

        switch (icon) {
            case Icons.MAP:
                props.value = 'map-o';
                return <FontAwesomeIcon {...props} />;
            case Icons.LOCATION:
                props.value = props.active ? ActiveLocation : InactiveLocation;
                props.className = (props.className ? props.className + " " : "") + Icons.LOCATION;
                props.imgStyle = {
                    width: '100%',
                    height: '100%'
                };
                return <SVGIcon {...props} />;
            case Icons.PENCIL_PAGE:
                if (props.style && (props.style.width || props.style.height)) {
                    ApplicationInstance.getInstance().getLogger().warn("SVGs shouldn't use CSS Height and Width. Please specify the height and width attributes on the props object.");
                    if (props.style.width) {
                        props.width = props.style.width;
                        delete props.style.width;
                    }
                    if (props.style.height) {
                        props.height = props.style.height;
                        delete props.style.height;
                    }
                }

                props.value = SVGUtils.PRESETS.createPencilPage();
                if (props.width) {
                    props.value.setWidth(props.width);
                }
                if (props.height) {
                    props.value.setHeight(props.height);
                }
                props.value.addClass(Icons.PENCIL_PAGE);
                if (props.className) {
                    props.value.addClass(props.className);
                }

                for (let i in props.style) {
                    props.value.setStyle(i, props.style[i]);
                }

                if (!props.strokeWidth) {
                    props.strokeWidth = 3;
                }
                props.value.setStrokeWidth(props.strokeWidth);

                if (!props.color) {
                    props.color = '#FFFFFF';
                }
                props.value.setStroke(props.color);

                //Ensures the .Icon div has the correct height and width.
                if (!props.style) {
                    props.style = {};
                }
                props.style.width = props.value.getWidth();
                props.style.height = props.value.getHeight();

                return <SVGIcon {...props} />;
            case Icons.EMPTY_CIRCLE: 
                props.value = 'circle-o'
                return <FontAwesomeIcon {...props} />;
            case Icons.CIRCLE:
                props.value = 'circle';
                return <FontAwesomeIcon {...props} />;
            case Icons.RESIZE_HANDLE:
                return (
                    <SVGIcon>
                        <svg width="22px" height="22px" viewBox="0 0 22 22" fill="#cbd6e2"><rect x="20" y="20" width="2" height="2"></rect><rect x="20" y="16" width="2" height="2"></rect><rect x="20" y="12" width="2" height="2"></rect><rect x="20" y="8" width="2" height="2"></rect><rect x="20" y="4" width="2" height="2"></rect><rect x="16" y="20" width="2" height="2"></rect><rect x="16" y="16" width="2" height="2"></rect><rect x="16" y="12" width="2" height="2"></rect><rect x="16" y="8" width="2" height="2"></rect><rect x="12" y="20" width="2" height="2"></rect><rect x="12" y="16" width="2" height="2"></rect><rect x="12" y="12" width="2" height="2"></rect><rect x="8" y="20" width="2" height="2"></rect><rect x="8" y="16" width="2" height="2"></rect><rect x="4" y="20" width="2" height="2"></rect><rect x="0" y="20" width="2" height="2"></rect><rect x="4" y="16" width="2" height="2"></rect><rect x="8" y="12" width="2" height="2"></rect><rect x="12" y="8" width="2" height="2"></rect><rect x="16" y="4" width="2" height="2"></rect><rect x="20" y="0" width="2" height="2"></rect></svg>;
                    </SVGIcon>
                );
            case Icons.HELP:
                props.value = 'question-circle';
                return <FontAwesomeIcon {...props} />;
            case Icons.CALENDAR:
                props.value = 'calendar';
                return <FontAwesomeIcon {...props} />;
            case Icons.WARNING: 
                props.value = 'exclamation-triangle';
                return <FontAwesomeIcon {...props} />;
            case Icons.CROSSHAIRS: 
                props.value = 'crosshairs';
                return <FontAwesomeIcon {...props} />;
            case Icons.UPLOAD:
                props.value = 'upload';
                return <FontAwesomeIcon {...props} />;
            case Icons.LONG_PRESS_HAND:
                props.value = LongPressHand;
                props.className = (props.className ? props.className + " " : "") + Icons.LONG_PRESS_HAND;
                return <SVGIcon {...props} />;
            case Icons.LOADING_SPINNER:
                props.value = 'spinner';
                return <FontAwesomeIcon {...props} />;
            case Icons.CHECK_CICLE_FILLED:
                props.value = 'check-circle';
                return <FontAwesomeIcon {...props} />;
            case Icons.LIST:
                props.value = 'list';
                return <FontAwesomeIcon {...props} />;
            case Icons.LOGS_LIST:
                props.value = LogsList;
                if (!props.width && !props.height) {
                    props.width = 48;
                    props.height = 48;
                }
                return <SVGIcon {...props} />;
            case Icons.CLOUD_UPLOAD:
                props.value = 'cloud-upload';
                return <FontAwesomeIcon {...props} />;
            case Icons.LANGUAGE:
                props.value = 'language'
                return <FontAwesomeIcon {...props} />;
            case Icons.CLOCK:
                props.value = 'clock-o';
                return <FontAwesomeIcon {...props} />;
            case Icons.CIRCULAR_LOGO_ICON:
                if (!props.width && !props.height) {
                    props.width = 150;
                    props.height = 150;
                }
                props.value = CircularLogo;
                return <SVGIcon {...props} />;
            case Icons.LOGO_ICON:
                props.value = TPLogoWhite;
                return <SVGIcon {...props} />;
            case Icons.CHECK_SQUARE:
                props.value = "check-square";
                return <FontAwesomeIcon {...props} />;
            case Icons.INFO:
                props.value = 'info-circle';
                return <FontAwesomeIcon {...props} />;
            case Icons.CIRCLE_CAMERA:
                if (props.className) {
                    props.className += " circle-camera";
                }
                else {
                    props.className = "circle-camera"; 
                }
                // CIRCLE_CAMERA uses the same FontAwesomeIcon as Camera. We apply custom CSS to add a circular border around the fa-camera element.
            case Icons.CAMERA:
                props.value = 'camera';
                return <FontAwesomeIcon {...props} />;
            case Icons.PLUS_CIRCLE:
                props.value = 'plus-circle';
                return <FontAwesomeIcon {...props} />;
            case Icons.PLUS_CIRCLE_THIN:
                props.value = PlusCircleThin;

                if (props.width && !props.height) {
                    props.height = props.width;
                }

                if (props.height && !props.width) {
                    props.width = props.height;
                }
            
                props.className = (props.className ? props.className + " " : "") + Icons.PLUS_CIRCLE_THIN;
                return <SVGIcon {...props} />;
            case Icons.MAP_MARKER:
                if (props.style && (props.style.width || props.style.height)) {
                    ApplicationInstance.getInstance().getLogger().warn("SVGs shouldn't use CSS Height and Width. Please specify the height and width attributes on the props object.");
                    if (props.style.width) {
                        props.width = props.style.width;
                        delete props.style.width;
                    }
                    if (props.style.height) {
                        props.height = props.style.height;
                        delete props.style.height;
                    }
                }

                props.value = SVGUtils.PRESETS.createMapMarker();
                if (props.width) {
                    props.value.setWidth(props.width);
                }
                if (props.height) {
                    props.value.setHeight(props.height);
                }
                props.value.addClass(icon);
                if (props.className) {
                    props.value.addClass(props.className);
                }

                for (let i in props.style) {
                    props.value.setStyle(i, props.style[i]);
                }

                if (!props.strokeWidth) {
                    props.strokeWidth = 3;
                }
                props.value.setStrokeWidth(props.strokeWidth);

                //Ensures the .Icon div has the correct height and width.
                if (!props.style) {
                    props.style = {};
                }
                props.style.width = props.value.getWidth();
                props.style.height = props.value.getHeight();

                if (props.value.getColor()) {
                    let color = Color.fromString(props.value.getColor());
                    props.value.setStroke(color.darken(1).toHexString());
                }
                return <SVGIcon {...props} />;
            case Icons.HOME:
                props.value = 'home';
                return <FontAwesomeIcon {...props} />;
            case Icons.UNDO:
                props.value = 'undo';
                return <FontAwesomeIcon {...props} />;
            case Icons.BACK:
                props.value = Arrow;
                if (!props.rotate) {
                    props.rotate = 180;
                }
                
                height = 31;
                width = 30;

                if (props.scale) {
                    height = Math.round(height * props.scale);
                    width = Math.round(width * props.scale);
                }

                if (!props.imgStyle) {
                    props.imgStyle = {};
                } 
                if (!props.imgStyle.verticalAlign) {
                    props.imgStyle.verticalAlign = "middle";
                }

                props.height = height + 'px';
                props.width = width + 'px';
                return <SVGIcon {...props} />;
            case Icons.DRAGGABLE:
                props.value = "data:image/svg+xml;charset=utf-8,%3Csvg width='5' height='24' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5px 24px'%3E%3Ctitle%3EGrippies%3C/title%3E%3Cpath fill='%23cbd6e2' d='M0 0h2v2H0V0zm0 8h2v2H0V8zm0 8h2v2H0v-2zM0 4h2v2H0V4zm0 8h2v2H0v-2zm0 8h2v2H0v-2zM3 0h2v2H3V0zm0 8h2v2H3V8zm0 8h2v2H3v-2zM3 4h2v2H3V4zm0 8h2v2H3v-2zm0 8h2v2H3v-2z'/%3E%3C/svg%3E";
                return <SVGIcon {...props} />;
            case Icons.ARROW_RIGHT:
                props.value = 'arrow-right';
                return <FontAwesomeIcon {...props} />;
            case Icons.WRENCH:
                props.value = 'wrench';
                return <FontAwesomeIcon {...props} />;
            case Icons.EDIT:
                props.value = 'pencil';
                return <FontAwesomeIcon {...props} />;
            case Icons.LINK:
                props.value = 'external-link';
                return <FontAwesomeIcon {...props} />;
            case Icons.IRI_NOTE:
                props.value = 'pencil-square-o';
                return <FontAwesomeIcon {...props} />;
            case Icons.PLUS_SQUARE:
                props.value = 'plus-square';
                return <FontAwesomeIcon {...props} />;
            case Icons.SAVE:
                props.value = 'floppy-o';
                return <FontAwesomeIcon {...props} />;
            case Icons.THIN_CANCEL:
                if (props.style && (props.style.width || props.style.height)) {
                    ApplicationInstance.getInstance().getLogger().warn("SVGs shouldn't use CSS Height and Width. Please specify the height and width attributes on the props object.");
                    if (props.style.width) {
                        props.width = props.style.width;
                        delete props.style.width;
                    }
                    if (props.style.height) {
                        props.height = props.style.height;
                        delete props.style.height;
                    }
                }

                props.value = SVGUtils.PRESETS.createThinCancelIcon();
                if (props.width) {
                    props.value.setWidth(props.width);
                }
                if (props.height) {
                    props.value.setHeight(props.height);
                }
                props.value.addClass(Icons.THIN_CANCEL);
                if (props.className) {
                    props.value.addClass(props.className);
                }

                for (let i in props.style) {
                    props.value.setStyle(i, props.style[i]);
                }

                //Ensures the .Icon div has the correct height and width.
                if (!props.style) {
                    props.style = {};
                }
                props.style.width = props.value.getWidth();
                props.style.height = props.value.getHeight();
                
                // Disable the inline-block inline style
                props.display = null;

                props.className = (props.className ? props.className + " " : "") + Icons.THIN_CANCEL;
                return <SVGIcon {...props} />;
            case Icons.CANCEL:
                props.value = 'times';
                return <FontAwesomeIcon {...props} />;
            case Icons.CANCEL_CIRCLE:
                props.value = 'times-circle-o';
                return <FontAwesomeIcon {...props} />;
            case Icons.CANCEL_CIRCLE_THIN:
                props.value = CancelCircleThin;

                if (props.width && !props.height) {
                    props.height = props.width;
                }

                if (props.height && !props.width) {
                    props.width = props.height;
                }

                props.className = (props.className ? props.className + " " : "") + Icons.CANCEL_CIRCLE_THIN;
                return <SVGIcon {...props} />;
            case Icons.REFRESH:
                props.value = 'refresh';
                return <FontAwesomeIcon {...props} />;
            case Icons.DELETE_THICK:
            case Icons.DELETE:
                //Delete defaults to the thick version.
                props.value = 'trash-o';
                return <FontAwesomeIcon {...props} />;
            case Icons.DELETE_THIN:
                props.value = 'trash';
                return <FontAwesomeIcon {...props} />;
            case Icons.MAP_THEME:
                props.value = 'globe';
                return <FontAwesomeIcon {...props} />;
            case Icons.CHEVRON_UP:
                props.value = 'chevron-up';
                return <FontAwesomeIcon {...props} />;
            case Icons.CHEVRON_LEFT:
                props.value = 'chevron-left';
                return <FontAwesomeIcon {...props} />;
            case Icons.CHEVRON_RIGHT:
                props.value = 'chevron-right';
                return <FontAwesomeIcon {...props} />;
            case Icons.CHEVRON_DOWN:
                props.value = 'chevron-down';
                return <FontAwesomeIcon {...props} />;
            case Icons.SEARCH:
                props.value = 'search';
                return <FontAwesomeIcon {...props} />;
            case Icons.LINE_CHART:
                props.value = 'line-chart';
                return <FontAwesomeIcon {...props} />;
            case Icons.BAR_CHART:
                props.value = 'bar-chart';
                return <FontAwesomeIcon {...props} />;
            case Icons.COG:
                props.value = 'cog';
                return <FontAwesomeIcon {...props} />;
            case Icons.MENU:
            case Icons.BARS:
                props.value = 'bars';
                return <FontAwesomeIcon {...props} />;
            case Icons.POWER_OFF:
                props.value = 'power-off';
                return <FontAwesomeIcon {...props} />;
            case Icons.TABLE:
                props.value = 'table';
                return <FontAwesomeIcon {...props} />;
            case Icons.PLUS:
                props.value = 'plus';
                return <FontAwesomeIcon {...props} />;
            case Icons.MINUS_SQUARE:
                props.value = 'minus-square';
                return <FontAwesomeIcon {...props} />;
            case Icons.MINUS:
                props.value = 'minus';
                return <FontAwesomeIcon {...props} />;
            case Icons.CHECK:
                props.value = 'check';
                return <FontAwesomeIcon {...props} />;
            case Icons.CARET_UP:
                props.value = 'caret-up';
                return <FontAwesomeIcon {...props} />;
            case Icons.CARET_LEFT:
                props.value = 'caret-left';
                return <FontAwesomeIcon {...props} />;
            case Icons.CARET_RIGHT:
                props.value = 'caret-right';
                return <FontAwesomeIcon {...props} />;
            case Icons.CARET_DOWN:
                props.value = 'caret-down';
                return <FontAwesomeIcon {...props} />;
            case Icons.SIGN_OUT:
                props.value = 'sign-out';
                return <FontAwesomeIcon {...props} />;
            default:
                throw new Error(`${icon} is not a creatable icon.`);
        }
    }
}

const IconFactory = new IconFactorySingleton();

IconFactory.Icons = Icons;

export {IconFactory};
