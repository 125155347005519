'use strict';

import {Action} from './Action';

class SetMetadataActionSingleton extends Action {
    getTag() {
        return 'set_metadata';
    }

    _execute({key, value} = {}) {
        return Promise.resolve({
            key: key,
            value: value
        });
    }
}

const SetMetadataAction = new SetMetadataActionSingleton();

export { SetMetadataAction };
