'use strict';

import {Action} from './Action';

class AddBodyClassAction extends Action {
    getTag() {
        return 'add-body-class';
    }

    _execute(className) {
        document.body.classList.add(className);
        return Promise.resolve();
    }
}

const AddBodyClass = new AddBodyClassAction();

export { AddBodyClass };
