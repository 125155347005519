'use strict';

import {Action} from './Action';

class AccessTokenUpdateSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'access_token_update';
    }

    /**
     * public execute
     * @return {void}
     */
    _execute({user} = {}) {
        return Promise.resolve(user);
    }
}

const AccessTokenUpdate = new AccessTokenUpdateSingleton();

export { AccessTokenUpdate };
