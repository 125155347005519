'use strict';

import {Action} from './Action';

class UpdateOrientationSingleton extends Action {
    constructor() { super(); }

    getTag() {
        return 'update_orientation';
    }

    _execute({ orientation } = {}) {
        return Promise.resolve(orientation);
    }
}

const UpdateOrientation = new UpdateOrientationSingleton();

export default UpdateOrientation;
export { UpdateOrientation };
