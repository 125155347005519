'use strict';

import {Action} from './Action';

/*
Used to create new distresses for a sample.
 */
class FlagUpdateActionSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'flag_update';
    }

    /**
     * public execute
     * 
     * @param  {String}  name       Flag Name
     * @param  {Boolean} value
     * @return {void}          
     */
    _execute({name, value} = {}) {
        return Promise.resolve({
            flagName : name,
            flagValue: value
        });
    }
}

const FlagUpdate = new FlagUpdateActionSingleton();

export { FlagUpdate };
