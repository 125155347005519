'use strict';

import { Action } from './Action';

/*
Used to create new distresses for a sample.
 */
class AppGeometryUpdateSingleton extends Action {
    constructor() {
        super();
    }

    getTag() {
        return 'app_geometry_update';
    }

    /**
     * public execute
     *
     *  Dispatches:
     *  {
     *      success : boolean,
     *      width   : Integer
     *      height  : Integer
     *  }
     * 
     * @param  {Integer} width
     * @param  {Integer} height
     * @return {void}          
     */
    _execute({width, height} = {}) {
        return Promise.resolve({
            success : true,
            width : width,
            height : height
        });
    }
}

const AppGeometryUpdate = new AppGeometryUpdateSingleton();

export { AppGeometryUpdate };
