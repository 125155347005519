'use strict';

import {Action} from './Action';

class AddMenuActionSingleton extends Action {
    getTag() {
        return 'add_menu';
    }

    _execute({menu} = {}) {
        return Promise.resolve(menu);
    }
}

const AddMenuAction = new AddMenuActionSingleton();

export { AddMenuAction };
